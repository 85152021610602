import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useRegister } from '../../hooks/register';
import fsIcon from '../../images/fs-icon.png';

export default function LoginPage() {
  let history = useHistory();
  let register = useRegister();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({ username: '', password: '', name: '', email: '' });

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);
    register.signup(
      user.username,
      user.password,
      user.name,
      user.email,
      data => {
        setIsLoading(false);
        history.replace({ pathname: '/' });
      },
      error => {
        setIsLoading(false);
        setError(error);
      }
    );
  };

  return (
    <div className="sidebar">
      <header>
        <div className="logo login">
          <img src={fsIcon} alt="" />
        </div>
      </header>
      <section className="main-content  overflow-y-auto">
        <div className="spacer-xs-4"></div>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h1 className="entry-title">
              <span> Your new Metaverse </span>
              <br />
              Create your Account
            </h1>
          </div>
        </div>
        <div className="spacer-xs-4"></div>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <form onSubmit={submit}>
              <div className="mb-3">
                <label className="form-label ms-3">Name</label>
                <input type="text" className="form-control" placeholder="Name" required={true} value={user.name} onChange={e => setUser({ ...user, name: e.target.value })} />
              </div>
              <div className="mb-3">
                <label className="form-label ms-3">Email</label>
                <input type="email" className="form-control" placeholder="Email" required={true} value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
              </div>
              <div className="mb-3">
                <label className="form-label ms-3">Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="username"
                  required={true}
                  value={user.username}
                  onChange={e => setUser({ ...user, username: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label ms-3">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required={true}
                  value={user.password}
                  onChange={e => setUser({ ...user, password: e.target.value })}
                />
              </div>
              <div className="spacer-xs-4"></div>
              <div className="d-grid gap-3">
                <button className="btn btn-primary" type="submit" disabled={isLoading}>
                  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
                  {isLoading ? 'Signing Up...' : 'Sign Up'}
                </button>
              </div>
              <div className="spacer-xs-2"></div>
              {error ? <div className="text-error text-center">Error: {error.message}</div> : null}
              <div className="spacer-xs-8"></div>
              <div className="d-grid gap-3">
                <Link to="/login" className="btn btn-link text-decoration-none text-white">
                  Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
      <footer className="login"></footer>
    </div>
  );
}
