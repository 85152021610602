import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import fsIcon from '../../images/fs-icon.png';

export default function LoginPage() {
  let history = useHistory();
  let auth = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ username: '', password: '' });

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);
    auth.signin(
      user.username,
      user.password,
      data => {
        setIsLoading(false);
        history.replace({ pathname: '/dashboard' });
      },
      error => {
        setIsLoading(false);
        setError(error);
      }
    );
  };

  return (
    <div className="sidebar custom-sign-page">
      <header>
        <div className="logo login">
          <img src={fsIcon} alt="" />
        </div>
      </header>
      <section className="main-content  overflow-y-auto">
        <div className="spacer-xs-4"></div>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h1 className="entry-title">
              <span> Your new Metaverse </span>
              <br />
              Login to your Account
            </h1>
          </div>
        </div>
        <div className="spacer-xs-4"></div>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <form onSubmit={submit}>
              <div className="mb-3">
                <label className="form-label ms-3">Account</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  required={true}
                  value={user.username}
                  onChange={e => setUser({ ...user, username: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label ms-3">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required={true}
                  value={user.password}
                  onChange={e => setUser({ ...user, password: e.target.value })}
                />
              </div>
              <div className="spacer-xs-4"></div>
              <div className="d-grid gap-3">
                <button className="btn btn-primary" type="submit" disabled={isLoading}>
                  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
                  {isLoading ? 'Login' : 'Login'}
                </button>
              </div>
              <div className="spacer-xs-2"></div>
              {error ? <div className="text-error text-center">Error: {error.message}</div> : null}
              <div className="spacer-xs-8"></div>
              <div className="d-grid gap-3">
                <Link to="/signup" className="btn btn-link text-decoration-none text-white">
                  Register
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Link className="text-white come-back-link" to="/">
        COME BACK
      </Link>
      <footer className="login custom-sign-footer"></footer>
    </div>
  );
}
