import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ProvideAuth } from '../hooks/auth';
import { ProvideRegister } from '../hooks/register';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import ParticipantPage from './pages/ParticipantPage';
import SignupPage from './pages/SignupPage';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import DownloadPage from './pages/DownloadPage';
import WelcomePage from './pages/WelcomePage';
import LearnMorePage from './pages/LearnMorePage';
import LogoutPage from './pages/LogoutPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import '../css/developer.css';
import '../scss/main.scss';

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <PublicRoute path="/" restricted={false} component={WelcomePage} exact={true} />
          <PublicRoute path="/learn-more" restricted={false} component={LearnMorePage} />
          <PublicRoute path="/login" restricted={true} component={LoginPage} />
          <PrivateRoute path="/dashboard" component={DashboardPage} />
          <PrivateRoute path="/logout" component={LogoutPage} />
          <PrivateRoute path="/download" restricted={false} component={DownloadPage} />
          <PublicRoute path="/participant/:scene/:mode/:key/:username" restricted={false} component={ParticipantPage} hasWhiteBackground={true} />
          <ProvideRegister>
            <PublicRoute path="/signup" restricted={true} component={SignupPage} />
            <PublicRoute path="/confirm-email" restricted={true} component={ConfirmEmailPage} />
          </ProvideRegister>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}
