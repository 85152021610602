export const scenes = {
  scenesList(cb, errorCb) {
    const formdata = new FormData();
    const token = localStorage.getItem('token');
    formdata.append('token', token);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/scene-list', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          let xmlData = '';
          var request = new XMLHttpRequest();
          request.open('GET', result.url, true);
          request.overrideMimeType('text/xml');
          request.onload = function () {
            if (request.readyState === request.DONE) {
              if (request.status === 200) {
                xmlData = request.responseText;
                cb({ xmlData: xmlData });
              }
            }
          };
          request.send(null);
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  },
  generateTempKey(sceneName, cb, errorCb) {
    const formdata = new FormData();
    const client_id = localStorage.getItem('client_id');
    const token = localStorage.getItem('token');
    formdata.append('scenename', sceneName);
    formdata.append('clientid', client_id);
    formdata.append('token', token);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/temp-key', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.status === 200) {
            cb(result);
          } else {
            errorCb(result);
          }
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  },
  autoLogin(machine, tempkey, cb, errorCb) {
    const formdata = new FormData();
    formdata.append('key', tempkey);
    formdata.append('machine', machine);
    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/auto-login', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.status === 200) {
            cb(result);
          } else {
            errorCb(result);
          }
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  }
};
