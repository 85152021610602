export const session = {
  stop(tempkey, cb, errorCb) {
    const formdata = new FormData();
    const token = localStorage.getItem('token');
    formdata.append('key', tempkey);
    formdata.append('token', token);
    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/stop-key-session', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.status === 200) {
            cb(result);
          } else {
            errorCb(result);
          }
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  }
};
