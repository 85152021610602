import { useEffect, useState } from 'react';
import { scenes } from '../../services/scenes';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { session } from '../../services/session';
import Modal from '../Modal';
import XMLParser from 'react-xml-parser';
import { Loader } from '../Loader';

const app = new window.Webex.Application();

export default function DashboardPage() {
  let history = useHistory();
  let auth = useAuth();
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [isSceneListLoaded, setIsSceneListLoaded] = useState(false);
  const [sessionStartError, setSessionStartError] = useState(false);
  const [error, setError] = useState(null);
  const [appReadyError, setAppReadyError] = useState(false);
  const [appReadyErrorMsg, setAppReadyErrorMsg] = useState(null);
  const [items, setItems] = useState([]);
  const [mode, setMode] = useState('viewer');
  const [active, setActive] = useState(false);
  const [activeSceneIndex, setActiveSceneIndex] = useState(0);
  const [sceneKey, setSceneKey] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const handleAppReady = () => {
    app
      .onReady()
      .then(() => {
        console.log('app:', app);
        setIsAppLoaded(true);
        app.context
          .getUser()
          .then(user => {
            console.log('user details:', user, user.displayName, user.email);
            setUserName(user.displayName);
            setUserEmail(user.email);
          })
          .catch(error => {
            console.log('Dashboard : getUser() promise failed ' + error);
          });
        if (!isSceneListLoaded) {
          scenes.scenesList(
            result => {
              setIsSceneListLoaded(true);
              _parseXML({ ...result });
            },
            error => {
              auth.signout(() => history.push('/'));
              // setIsSceneListLoaded(false);
              setError(error);
            }
          );
        }
      })
      .catch(error => {
        setAppReadyError(true);
        setAppReadyErrorMsg('onReadyError');
      });
  };

  const _parseXML = ({ xmlData }) => {
    var xml = new XMLParser().parseFromString(xmlData);
    let names = [];
    let prefix = '';
    if (xml) {
      xml.children.forEach((element, index) => {
        if (element.name === 'Prefix') {
          prefix = element.value;
        }
        if (element.name === 'Contents') {
          let tempName = element.children[0]['value'];
          tempName = tempName.replace(prefix + '/', '');
          let tempNameSplit = tempName.split('/');
          let sceneName = tempNameSplit[0];
          if (sceneName !== '' && !names.includes(sceneName)) {
            names.push(sceneName);
          }
        }
      });
    }
    setItems(names);
  };

  useEffect(() => {
    handleAppReady();
  });

  const shareUrl = () => {
    if (active) {
      session.stop(
        sceneKey,
        data => {
          app.clearShareUrl();
        },
        error => {
          console.log('session stop API error:', error);
        }
      );
    } else {
      if (app.isShared) {
        setSessionStartError(true);
        return;
      }
      scenes.generateTempKey(
        items[activeSceneIndex],
        data => {
          setSceneKey(data.key);
          let username = localStorage.getItem('username');
          const url = `${process.env.REACT_APP_VECTION_URL}/participant/${data.sceneName}/${mode}/${data.key}/${username}`;
          console.log('url:', url);
          app
            .setShareUrl(url, url, 'Vection FrameS')
            .then(() => {
              let urlParms = new URLSearchParams({
                mode: 'host',
                key: data.key,
                name: userName,
                email: userEmail
              });
              console.log('FrameS url:', 'frames://app?' + urlParms);
              window.location = 'frames://app?' + urlParms;
            })
            .catch(error => {
              setSessionStartError(true);
              console.log('setShareUrl() failed with error', error);
            });
        },
        error => {
          console.log('scenes generateTempKey API error:', error);
        }
      );
    }
    setActive(!active);
  };

  const handleMode = mode => {
    !active && setMode(mode);
  };

  return isAppLoaded ? (
    <>
      {sessionStartError && (
        <Modal title="Error" message="Could not start/stop while session is active. Please stop session first and try again." handleClose={() => setSessionStartError(false)} />
      )}
      <section className="main-content scene-list overflow-y-auto">
        <div className="spacer-xs-4"></div>
        <div className="row justify-content-center">
          <div className="col-sm-11">
            <h1 className="fs-2 text-black">
              <span>Scene List</span>
              <br />
              Create your Metaverse.
            </h1>
            <div className="spacer-xs-4"></div>
            <div className="row justify-content-center">
              <div className="col-sm-6">
                <div className=" d-flex justify-content-around">
                  <div className="form-check form-check-inline">
                    <input
                      className={'form-check-input custom-clickable ' + (active && 'not-allowed')}
                      type="radio"
                      name="modeRadio"
                      id="modeRadio-editor"
                      onChange={() => {
                        handleMode('editor');
                      }}
                      value="editor"
                      checked={mode === 'editor'}
                    />
                    <label
                      className={'form-check-label custom-clickable ' + (active && 'not-allowed')}
                      onClick={() => {
                        handleMode('editor');
                      }}
                    >
                      Editor
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className={'form-check-input custom-clickable ' + (active && 'not-allowed')}
                      type="radio"
                      name="modeRadio"
                      id="modeRadio-viewer"
                      value="viewer"
                      onChange={() => {
                        !active && handleMode('viewer');
                      }}
                      checked={mode === 'viewer'}
                    />
                    <label
                      className={'form-check-label custom-clickable ' + (active && 'not-allowed')}
                      onClick={() => {
                        handleMode('viewer');
                      }}
                    >
                      Viewer
                    </label>
                  </div>
                </div>
                <div className="spacer-xs-4"></div>
                <div className="d-grid gap-3">
                  <span type="button" className="btn btn-secondary" onClick={() => shareUrl()}>
                    {active ? 'Stop your scene' : 'Start your scene'}
                  </span>
                </div>
              </div>
            </div>

            <div className="spacer-xs-4"></div>

            <ListData
              key={'scene-list-data'}
              error={error}
              isSceneListLoaded={isSceneListLoaded}
              items={items}
              setSessionStartError={setSessionStartError}
              activeSceneIndex={activeSceneIndex}
              setActiveSceneIndex={setActiveSceneIndex}
              sessionIsActive={active}
            />
          </div>
          <div className="spacer-xs-2"></div>
        </div>
      </section>

      <footer className="login"></footer>
      <Link className="text-white dashboard come-back-link" to="/">
        COME BACK
      </Link>
    </>
  ) : appReadyError ? (
    <p>{appReadyErrorMsg}</p>
  ) : (
    <Loader />
  );
}

function ListData({ error, isSceneListLoaded, items, setSessionStartError, activeSceneIndex, setActiveSceneIndex, sessionIsActive }) {
  if (error) {
    return <div className="col-12 mt-3 text-center">Error: {error.message}</div>;
  } else if (!isSceneListLoaded) {
    return <div className="col-12 mt-3 text-center">Loading...</div>;
  } else {
    if (items && items.length > 0) {
      return (
        <div className="row">
          <div className="col-sm-12">
            <ul className="test p-0 list-unstyled">
              {items.map((item, index) => (
                <ListRadio
                  key={'scene-list-radio' + index}
                  item={item}
                  index={index}
                  setSessionStartError={setSessionStartError}
                  activeSceneIndex={activeSceneIndex}
                  setActiveSceneIndex={setActiveSceneIndex}
                  sessionIsActive={sessionIsActive}
                />
              ))}
            </ul>
          </div>
        </div>
      );
    } else {
      return <div className="col-12 mt-3 text-center">No Records Found</div>;
    }
  }
}

function ListRadio({ item, index, activeSceneIndex, setActiveSceneIndex, sessionIsActive }) {
  return (
    <>
      <li className="mb-2">
        <div
          className={'form-check scene-list-item-div custom-clickable ' + (sessionIsActive && 'not-allowed')}
          onClick={() => {
            !sessionIsActive && setActiveSceneIndex(index);
          }}
        >
          <input
            className={'form-check-input ' + (sessionIsActive && 'not-allowed')}
            type="radio"
            name="sceneName"
            id={'scene-radio-' + index}
            checked={index === activeSceneIndex}
            onChange={() => {
              !sessionIsActive && setActiveSceneIndex(index);
            }}
          />
          <label className={'form-check-label'} style={{ textTransform: 'capitalize' }}>
            {item}
          </label>
        </div>
      </li>
    </>
  );
}
