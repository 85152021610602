import fsIcon from '../../images/fs-icon.png';
import mindesk from '../../images/mindesk.png';
import presentation from '../../images/presentation.png';
import platform from '../../images/platform.jpg';
import { Link } from 'react-router-dom';

const LearnMorePage = () => {
  return (
    <>
      <div className="sidebar">
        <header>
          <div className="logo login detail">
            <img src={fsIcon} alt="" />
          </div>
        </header>
        <section className="main-content detail overflow-auto overflow-y-auto">
          <div className="spacer-xs-4"></div>
          <div className="row justify-content-center">
            <div className="col-sm-11">
              <h1 className="entry-title">
                <span> Your new way to collaborate. </span>
                <br />
                Create your metaverse
              </h1>
              <div className="spacer-xs-2"></div>
              <div className="entry-para">
                <p className="text-white text-justified">
                  FrameS is your collaborative virtual environment where you can have meetings and presentation with unlimited possibilities thanks to Virtual Reality and other
                  disruptive technologies. Connect people globally form different devices, engage clients and colleagues, improve virtual meeting with the power of Extended Reality
                  (XR). Metaverse becomes real.
                </p>
                <div className="spacer-xs-3"></div>
                <iframe
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/FNR1DOofVsM"
                  title="YouTube video player"
                  frameBorder={'0'}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="spacer-xs-4"></div>
              <div className="how-works">
                <div className="d-flex">
                  <div className="entry-content">
                    <h2>How Does it work?</h2>
                    <p className="text-justified">
                      Working on your PC, Smaertphone and Tablet, FrameS allows you to set-up and experience a virtual meeting or presentationin a new way. An easy-to-use set of
                      tools give you the possibility to access pre-defined content or to create your own. You can import your 3D models, PDF, videos and images in FrameS to set-up
                      the space.
                    </p>
                    <Link to={'/login'} className="btn btn-secondary px-5">
                      Get Started
                    </Link>
                  </div>
                  <div className="entry-thumb">
                    <img src={mindesk} alt="" />
                  </div>
                </div>
              </div>
              <div className="spacer-xs-4"></div>
              <div className="presenttation-img">
                <img src={presentation} alt="" className="img-fluid" />
              </div>
              <div className="spacer-xs-4"></div>
              <div className="entry-useCase">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="entry-thumb">
                      <img src={platform} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <div className="entry-content">
                      <h2>Use Case</h2>
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Sales and Marketing Presentation
                            </button>
                          </h2>
                          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-justified">
                              Use FrameS to set-up your presentation in a 3D environment to engage management, clients and dealer networks.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Collaborate
                            </button>
                          </h2>
                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-justified">
                              Use FrameS to set-up your presentation in a 3D environment to engage management, clients and dealer networks.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Create your events
                            </button>
                          </h2>
                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-justified">
                              Use FrameS to set-up your presentation in a 3D environment to engage management, clients and dealer networks.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Showroom
                            </button>
                          </h2>
                          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-justified">
                              Use FrameS to set-up your presentation in a 3D environment to engage management, clients and dealer networks.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="spacer-xs-8"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="d-grid gap-3">
                      <a className="btn btn-secondary" href="https://vection-technologies.com">
                        Discover Vection Technologies
                      </a>
                    </div>
                  </div>
                </div>
                <div className="spacer-xs-6"></div>
              </div>
            </div>
          </div>
        </section>
        <footer className="login"></footer>
      </div>
    </>
  );
};

export default LearnMorePage;
