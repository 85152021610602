import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../services/auth';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const _setUserDetails = ({ data, username }) => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('name', data.name);
    localStorage.setItem('username', username);
    localStorage.setItem('client_id', data.client_id);
    localStorage.setItem('expiryDate', data.expiryDate);
    setUser('user');
  };

  const _removeUserDetails = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('client_id');
    localStorage.removeItem('username');
    localStorage.removeItem('expiryDate');
    setUser(null);
  };

  const signin = (username, password, cb, errorCb) => {
    return auth.signin(
      username,
      password,
      data => {
        _setUserDetails({ data, username });
        cb(data);
      },
      error => {
        _removeUserDetails();
        errorCb(error);
      }
    );
  };

  const signout = cb => {
    return auth.signout(() => {
      _removeUserDetails();
      cb();
    });
  };

  const isLoggedIn = () => {
    let now = (new Date().getTime() / 1000).toFixed(0);
    return localStorage.getItem('token') && localStorage.getItem('expiryDate') && now < localStorage.getItem('expiryDate') ? true : false;
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setUser('user');
    } else {
      setUser(null);
    }
  }, []);

  return {
    user,
    isLoggedIn,
    signin,
    signout
  };
}
