import { createContext, useContext, useEffect } from 'react';
import { register } from '../services/register';

const registerContext = createContext();

export function ProvideRegister({ children }) {
  const register = useProvideRegister();
  return <registerContext.Provider value={register}>{children}</registerContext.Provider>;
}

export const useRegister = () => {
  return useContext(registerContext);
};

function useProvideRegister() {
  const signup = (username, password, name, email, cb, errorCb) => {
    return register.signup(
      username,
      password,
      name,
      email,
      data => {
        cb(data);
      },
      error => {
        errorCb(error);
      }
    );
  };

  const verifyEmail = (validation_token, cb, errorCb) => {
    return register.verifyEmail(
      validation_token,
      data => {
        cb(data);
      },
      error => {
        errorCb(error);
      }
    );
  };

  useEffect(() => {}, []);

  return {
    signup,
    verifyEmail
  };
}
