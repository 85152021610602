export const register = {
  signup(username, password, name, email, cb, errorCb) {
    const formdata = new FormData();
    formdata.append('username', username);
    formdata.append('password', password);
    formdata.append('name', name);
    formdata.append('email', email);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/sign-up', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success) {
            cb(result);
          } else {
            errorCb(result);
          }
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  },
  verifyEmail(validation_token, cb, errorCb) {
    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/confirm-email?validation_token=' + validation_token, {
      method: 'GET'
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          cb(result);
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  }
};
