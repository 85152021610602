import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { useToggle } from '../hooks/toggle';

export default function Navbar() {
  let history = useHistory();
  let { pathname } = useLocation();
  let pathnameSplit = pathname.substring(1, pathname.length).split('/');
  let isParticipantPage = pathnameSplit[0] === 'participant';
  let auth = useAuth();
  const [isNavbarOpen, setIsNavbarOpen] = useToggle();

  let logout = () => {
    isNavbarOpen && setIsNavbarOpen(false);
    auth.signout(() => history.push('/'));
  };
  if (isParticipantPage) {
    return (
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-blacklight">
          <div className="container-fluid">
            <span className="navbar-brand">Vection</span>
          </div>
        </nav>
      </header>
    );
  }
  return auth.user ? (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-blacklight">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/dashboard">
            Vection
          </Link>
          <button
            className={'navbar-toggler ' + (isNavbarOpen ? 'collapsed' : '')}
            onClick={setIsNavbarOpen}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={'collapse navbar-collapse ' + (isNavbarOpen ? 'show' : '')} id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/dashboard"
                  onClick={() => {
                    isNavbarOpen && setIsNavbarOpen(false);
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/download"
                  onClick={() => {
                    isNavbarOpen && setIsNavbarOpen(false);
                  }}
                >
                  Download
                </Link>
              </li>
            </ul>
            <div className="d-flex">
              <button className="btn btn-primary p-2" onClick={logout}>
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  ) : (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-blacklight">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            Vection
          </Link>
          <button
            className={'navbar-toggler ' + (isNavbarOpen ? 'collapsed' : '')}
            onClick={setIsNavbarOpen}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={'collapse navbar-collapse ' + (isNavbarOpen ? 'show' : '')} id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/signup"
                  onClick={() => {
                    isNavbarOpen && setIsNavbarOpen(false);
                  }}
                >
                  SignUp
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/login"
                  onClick={() => {
                    isNavbarOpen && setIsNavbarOpen(false);
                  }}
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
